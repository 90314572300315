import styled from 'styled-components'


export const Container = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

`

export const Tittle = styled.p`
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
`

export const Helper = styled.span`
    font-size: 0.8rem;
    color: white;
    background-color: red;
    border-radius: 1rem;
    text-align: center;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > label {
        margin-left: 10px;
    }

`
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 90%;
    height: 200px;
    background-color: aqua;
    border-radius: 15px;
    align-items: center;
    justify-content: centers;

`